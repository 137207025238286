import React from "react"

const Index = ({ imgSrc, title, date, count, isDetail }) => {
  return (
    <div className="gallery-container">
      <img className="thumbnail" src={imgSrc} alt="" />
      {!isDetail && (
        <div className="caption-wrapper">
          <div className="caption-content">
            <label className="title">{title.toUpperCase()}</label>
            <div className="d-flex justify-content-between">
              <span className="date">{date}</span>
              <div>
                <img
                  src="../../../../../images/img_counter.png"
                  alt="img-counter"
                />
                <span className="counter">{count}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Index
